import React from 'react'
import styled from '@emotion/styled'
import TextLink from 'src/components/TextLink'
import Link from 'src/components/Link'
import ClippedImage from 'src/components/ClippedImage'
import { typography, animations, colors } from 'src/styles'

const Wrapper = styled.div`
	padding-bottom: 10px;
	&:hover {
		color: ${ colors.pink5 };
	}
`

const ItemTextLink = styled(TextLink)`
	color: ${ colors.pink4 };
	${ typography.tinyCaps }
	margin-top: .5em;
	svg {
		* {
			stroke: ${ colors.pink4 };
			stroke-dasharray: 200%;
			stroke-dashoffset: 40%;
		}
	}
`

const ImageWrapper = styled(Link)`
	.gatsby-image-wrapper {
		transition: transform ${ animations.mediumSpeed } ease-in-out;
	}
	&:hover {
		.gatsby-image-wrapper {
			transform: scale(1.05);
		}
	}
`

const TextWrapper = styled(Link)`
	h6 {
		text-align: center;
		margin-bottom: 0;
		padding-top: 1.2em;
	}

	p {
		${ typography.bodyTiny };
		line-height: 1.6em;
		text-align: center;
		margin-top: .4em;
	}
`

const ActionWrapper = styled.div`
	// padding: 16px 10px 0;
	width: 100%;
	// display: inline-block;
	display: flex;
	justify-content: center;
	vertical-align: middle;
`

const Clips = ['raggedThumb1', 'raggedThumb2', 'raggedThumb3']

const CocktailCard = ({
	className,
	title,
	tagline,
	image,
	slug,
	index,
	sizes
}) => {
	let buttonStagger = 0
	if (index % 3) {
		buttonStagger = 1
	} else if (index % 2) {
		buttonStagger = 2
	}
	return (
		<Wrapper className={className}>
			{image && image.gatsbyImageData && (
				<ImageWrapper to={'/cocktails/' + slug}>
					<ClippedImage
						image={image}
						clipPathId={Clips[(index + 1) % 3]}
						sizes={sizes}
					/>
				</ImageWrapper>
			)}
			<TextWrapper to={'/cocktails/' + slug}>
				<h6>{title}</h6>
			</TextWrapper>
			<ActionWrapper>
				<ItemTextLink
					size='tiny'
					setTheme='white'
					to={'/cocktails/' + slug}
					title='View Recipe'
					name='View Recipe'
					active={true}
					stagger={buttonStagger}
				>
					View Recipe
				</ItemTextLink>
			</ActionWrapper>
		</Wrapper>
	)
}

export default CocktailCard
